
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "Generals",

  setup() {
    const store = useStore();
    // const user = computed(() => store.getters.currentUser);
    const u = localStorage.getItem("user") as any;
    const user = JSON.parse(u);
    const dataList = [
      {
        name: "type_entities",
        route: "/type-entities",
        icon: "bi bi-diagram-3",
        count: "",
        permission: "type entities: index",
      },
      {
        name: "address_type",
        route: "/address_type",
        icon: "bi bi-geo-alt",
        count: "",
        permission: "addresses: index",
      },
      {
        name: "contacts_type",
        route: "/contacts_type",
        icon: "bi bi-person-lines-fill",
        count: "",
        permission: "contacts type: index",
      },
      {
        name: "sectors_type",
        route: "/sectors_type",
        icon: "bi bi-hospital",
        count: "",
        permission: "sectors type: index",
      },
      {
        name: "media_type",
        route: "/media_type",
        icon: "bi bi-mailbox",
        count: "",
        permission: "media type: index",
      },
      {
        name: "iparticipantsType",
        route: "/participant_type",
        icon: "las la-user-tag",
        count: "",
        permission: "participant type: index",
      },
      {
        name: "iPackagingType",
        route: "/packaging_type",
        icon: "las la-boxes",
        count: "",
        permission: "participant type: index",
      },
      {
        name: "iidentificationtypes",
        route: "/identification_type",
        icon: "las la-stream",
        count: "",
        permission: "identification type: index",
      },
      {
        name: "icountries",
        route: "/countries",
        icon: "las la-globe",
        count: "",
        permission: "participant type: index",
      },
      {
        name: "dashboard",
        route: "/dashboards",
        icon: "bi bi-diagram-3",
        count: "",
        permission: "type entities: index",
      },
    ];
    const dataObject = ref<[] | any>([]);
    const search = ref("");
    const permissions = ref<[] | any>([]);

    const onSearch = () => {
      dataObject.value = dataList.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const canViewCatalog = (item) => {
      if (JSON.stringify(user) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === item.permission).length > 0
        );
      }
      return false;
    };

    onMounted(() => {
      store.commit("setLoadingStatus", true);
      ApiService.get(`/api/permissions/me`)
        .then(({ data }) => {
          permissions.value = data;
        })
        .catch((error) => {
          console.log(error);
        });
      setCurrentPageBreadcrumbs("generals", []);
      dataObject.value = dataList;
      ApiService.get("/api/catalogs/general").then(({ data }) => {
        dataObject.value[0].count = data.entity_types;
        dataObject.value[1].count = data.address_types;
        dataObject.value[2].count = data.contact_types;
        dataObject.value[3].count = data.sector_types;
        dataObject.value[4].count = data.media_types;
        dataObject.value[5].count = data.participant_types;
        dataObject.value[6].count = data.packaging_types;
        dataObject.value[7].count = data.identification_types;
        dataObject.value[8].count = data.country;
        dataObject.value[9].count = data.dashboards;
        store.commit("setLoadingStatus", false);
      });
    });

    return {
      dataObject,
      dataList,
      search,
      canViewCatalog,
      permissions,
      onSearch,
    };
  },
});
